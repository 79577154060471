<template>
  <div>
    <h2 class="text-xl font-semibold text-gray-900">
      Lijst
    </h2>

    <div class="py-4">
      <div v-if="!items?.length" class="no-data">
        Geen vouchers gevonden
      </div>

      <VouchersTable :items="items" />
      <UITablePagination
        v-model:limit="limit"
        v-model:offset="offset"
        class="mt-2"
        :count="count"
      />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'

import VouchersTable from '@/pages/administratie/vouchers/Table.vue'

import usePaginatedApi from '@/hooks/usePaginatedApi'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const { limit, offset, count, items } = usePaginatedApi('VOUCHERS_LIST')
</script>
